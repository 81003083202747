// const config = {
//   BASE_URL: process.env.REACT_APP_BASE_URL,
//   LOCAL_BASE_URL: process.env.REACT_APP_LOCAL_BASE_URL,
// };
// console.log("BASE_URL:", config.BASE_URL);
// console.log("LOCAL_BASE_URL:", config.LOCAL_BASE_URL);
// export default config;

const config = {
  LOCAL_BASE_URL: process.env.REACT_APP_LOCAL_BASE_URL, // Development URL
  BASE_URL: process.env.REACT_APP_BASE_URL, // Production URL
};

export default config;
