import React from "react";
// import { useNavigate } from "react-router-dom";
import Profile from "../components/Profile";

const Home = () => {
  return (
    <div>
      <Profile />
    </div>
  );
};

export default Home;
